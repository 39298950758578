import {Helmet} from "react-helmet-async";
import {Header} from "@components/Header/Header";
import {Wrapper} from "@components/Wrapper";
import {PrivateAccessStatus} from "@pages/Private/parts";
import {Spiner} from "@components/Spinner";
import {useEffect, useState} from "react";

import styles from './styles.scss';
import {addPageData, setPageData} from "@slices/pageDataSlice";
import {getAttachment, getPrivatePost} from "@utils/fetches";
import {useNavigate, useParams} from "react-router-dom";
import toast from "react-hot-toast";
import {useDispatch, useSelector} from "react-redux";
import {isImage, isVideo} from "@pages/Details/Components/Carousel/utils/isVideo";
import {baseUrl, headers} from "@utils/constants";
import ReactMarkdown from "react-markdown";
import dayjs from "dayjs";
import {useTranslation} from "react-i18next";
import axios from "axios";
import {Avatar, Button, SecuredImage} from "@components/elememts";
import {Modal} from "@components/Modules";
import {Player} from "@components/elememts/Player";
import classNames from "classnames";

export const Post = () => {

    const {post = {}, user} = useSelector((state) => state.pageData?.['private.post']) || {};
    const {attachments = [], comments = []} = post;

    const sortedAttachments = attachments?.length > 0 ? Array.from(attachments).sort((a, b) => isVideo(a.ext) ? -1 : 1) : [];

    const [pageLoaded, setPageLoaded] = useState(false);
    const [comment, setComment] = useState('');
    const [sendCommentRequested, setSendCommentRequested] = useState(false);

    //Состоятние индекса просматриваемого в модалке вложения
    const [attachmentIndex, setAttachmentIndex] = useState(null);

    const {id} = useParams();

    const dispatch = useDispatch();

    const {t} = useTranslation();
    const navigate = useNavigate();

    useEffect(() => {
        dispatch(setPageData({page: 'private.post', data: {}}));
        getPrivatePost(id).then(response => {
            if(response.status){
                dispatch(addPageData({page: 'private.post', data: response.data}));
                setPageLoaded(true);
            }else{
                toast.error(response.data?.message || t('common.errors.requestFailed'));
                navigate('/private');
            }
        }).catch(error => {
            toast.error(t('common.errors.requestFailed'));
        });
    }, []);

    const sendComment = () => {
        if(sendCommentRequested){
            return;
        }

        setSendCommentRequested(true);

        axios
            .post(baseUrl + '/api/comments', {
                data: {
                    text: comment,
                    post: id,
                    user: user.id
                }
            }, headers())
            .then(response => {
                setComment('');
                dispatch(addPageData({page: 'private.post', data: {
                    post: {...post, comments: [{id: response.data.data.id, text: comment, user: user, createdAt: new Date()}, ...comments]}
                }}));
            })
            .catch(error => {
                toast.error(t('common.errors.requestFailed'));
            })
            .finally(() => {
                setSendCommentRequested(false);
            });
    };

    const attachment = sortedAttachments?.[attachmentIndex] || null;

    return <>
        <Helmet>
            <title>Радикальня | {t('pages.private')} | {post.title || ''}</title>
        </Helmet>

        <Header/>
        <Wrapper>
            {pageLoaded ? <div className={styles.post}>
                <div className={styles.top}>
                    <h1>{post.title}</h1>
                    <Button navLink={`/private/`}>{t('details.button')}</Button>
                </div>
                <div className={styles.gallery}>
                    {attachments.length > 0 && sortedAttachments.map((attachment, index) =>
                        <AttachmentPreview {...attachment} key={attachment.id} onClick={() => setAttachmentIndex(index)}/>)}
                </div>
                <div className={styles.content}>
                    <div className={styles.info}>
                        <div className={styles.views}>
                            {t('details.views')}: <b>{post.views}</b>
                        </div>
                        {post.description?.length > 5 && <div className={styles.description}>
                            <ReactMarkdown>{post.description}</ReactMarkdown>
                        </div>}
                        <div className={styles.links}>
                            {post.whoreLink?.length > 4 && <div>
                                {t("details.links")}: <a href={post.whoreLink} target='_blank'>{post.whoreLink}</a>
                            </div>}
                            {post.friend_list?.url?.length > 4 && <div>
                                {t("details.whoreFriends")}: <Button onClick={() => window.open(baseUrl + post.friend_list.url, '_blank')} color={'red-filled'} className={styles.button}>
                                    {t("details.open")}
                            </Button>
                            </div>}
                        </div>

                    </div>
                    <div className={styles.comments}>
                        <div className={styles.title}>{t('details.comments.title')}</div>
                        <div className={styles.list}>
                            {comments.length ? comments.map(comment => <div key={comment.id} className={styles.item}>
                                <Avatar user={comment.user} className={styles.avatar}/>
                                <div className={styles.comment}>
                                    <div className={styles.text}>{comment.text}</div>
                                    <div className={styles.data}>
                                        <div>{t('details.comments.author')}: {comment.user?.username || t('details.comments.anonymous')}</div>
                                        <div>{dayjs(comment.createdAt).format('DD.MM.YYYY HH:mm')}</div>
                                    </div>
                                </div>
                            </div>) : <div>{t('details.comments.noComments')}</div>}

                        </div>
                        <div className={styles.formWrapper}>
                            <textarea value={comment} onChange={e => setComment(e.target.value)}></textarea>
                            <Button disabled={comment.length <= 1 || sendCommentRequested} onClick={sendComment}>
                                {t('details.comments.send')}
                            </Button>
                        </div>
                    </div>
                </div>

                {attachment && <Modal opened onClose={() => setAttachmentIndex(null)}>
                    <div className={styles.modal}>
                        <h3>{post.title}</h3>
                        <div className={styles.media}>
                            {isVideo(attachment?.ext) && <Player url={attachment.cdnLink || baseUrl + attachment.url}/>}
                            {isImage(attachment?.ext) && <SecuredImage url={attachment.url} id={attachment.id} key={attachment.id}/>}
                        </div>
                        <div className={styles.buttons}>
                            <Button onClick={() => setAttachmentIndex(attachmentIndex - 1)} disabled={attachmentIndex === 0}>
                                {t('details.previous')}
                            </Button>
                            <Button onClick={() => setAttachmentIndex(attachmentIndex + 1)} disabled={attachmentIndex === attachments.length - 1}>
                                {t('details.next')}
                            </Button>
                        </div>
                    </div>
                </Modal>}
            </div> : <Spiner/>}
        </Wrapper>
    </>;
};

const AttachmentPreview = (props) => {
    return <div className={styles.attachmentPreview} onClick={props.onClick}>
        {isVideo(props.ext) ? <VideoThumb url={props.url}/> : <ImageThumb url={props.url} id={props.id}/>}
    </div>
};

const ImageThumb = ({url, id}) => {
    //const [image, setImage] = useState(require('@assets/default.webp'));

    // useEffect(() => {
    //     (async () => {
    //         const image = await getAttachment(id).then(res => res).catch(err => require('@assets/default.webp'));
    //         setImage(image);
    //     })();
    // }, []);
    const image = baseUrl + url;
    return <div className={styles.img} style={{backgroundImage: `url(${image})`}}></div>
};

const VideoThumb = ({url}) => {
    //Показываем картинку видео
    return <img src={require('@assets/play.webp')} alt={'video'} className={styles.video}/>;
};