import React, { useEffect } from 'react';
import {useLocation, useNavigate} from 'react-router-dom';
import {fetchUser} from "@slices/userSlice";
import {useDispatch, useSelector} from "react-redux";

const TelegramAuthCallback = () => {
    const { token } = useSelector((state) => state.user);

    const location = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    useEffect(() => {

        const urlFragment = location.hash;
        const params = new URLSearchParams(urlFragment.replace('#', '')); // Удаляем символ #
        const tgAuthResult = params.get('tgAuthResult');
        if (tgAuthResult) {
            // Декодирование данных из Base64
            const data = JSON.parse(atob(tgAuthResult));
            console.log('data', data);
            // Отправка данных на сервер
            dispatch(fetchUser(data));
        }

    }, [location.hash]);

    useEffect(() => {
        console.log('token', token);
        if(token){
            navigate('/');
        }
    }, [token]);

    return (
        <div>
            <h1>Telegram Auth Successful!</h1>
            <p>Processing data...</p>
        </div>
    );
};

export default TelegramAuthCallback;
