import styles from './styles.scss';
import {useEffect, useState} from "react";
import {getAttachment} from "@utils/fetches";
import {baseUrl} from "@utils/constants";

export const SecuredImage = ({url, id, alt, ...props}) => {
    //const [image, setImage] = useState(require('@assets/default.webp'));

    // useEffect(() => {
    //     (async () => {
    //         const image = await getAttachment(id).then(res => res).catch(err => require('@assets/default.webp'));
    //         setImage(image);
    //     })();
    // }, []);

    const image = baseUrl + url;

    return <div style={{backgroundImage: `url('${image}')`}} className={styles.securedImage}></div>;
};