import bitcoin from '@assets/icons/bitcoin.svg';
import monobank from '@assets/icons/monobank.svg';
import paypal from '@assets/icons/paypal.svg';
import usdt from '@assets/icons/usdt.svg';
import {Button} from '@components/Button';
import {LeakCover} from '@components/elememts';
import {Spiner} from '@components/Spinner';
import styled from "@emotion/styled";
import {setPageData} from '@slices/pageDataSlice';
import {setParam} from '@slices/paramsSlice';
import {useEffect, useState} from 'react';
import { Helmet } from "react-helmet-async";
import { useDispatch, useSelector } from "react-redux";
import defaultPic from "@assets/default.webp";
import {baseUrl, defaultDescription, botId, headers} from "@utils/constants";
import { useTranslation } from "react-i18next";
import { WrapperWithImage } from "@components/WrapperWithImage";
import { TelegramButton } from "@components/TelegramButton";
import {fetchUser, logout} from "@slices/userSlice";
import { Header } from "@components/Header/Header";
import Logo from "@assets/logo-main.svg";
import TgChannelLogo from "@assets/tgChannelLogo.jpg";
import { Blur } from "@components/Blur";
import { currentLanguage } from "@locales/i18n";
import {useNavigate, useSearchParams} from 'react-router-dom';

import {Auth, Registration, ResetPassword, ForgotPassword} from './parts/forms';
import styles from './styles.scss';
import {getMainPage} from "@utils/fetches";
import i18n from "i18next";

export const Main = () => {
  const {t} = useTranslation();
  const [searchParams] = useSearchParams();
  const {token} = useSelector((state) => state.user);
  const {mainPageForm = ''} = useSelector((state) => state.params);
  const {posts = [], wallets = [], about = {}} = useSelector((state) => state.pageData.main) || {};
  const dispatch = useDispatch();
  const currentLng = currentLanguage();

  const [pageLoaded, setPageLoaded] = useState(false);

  const navigate = useNavigate();

  const language = i18n.language;

  const mainPageForms = {
    auth: Auth,
    registration: Registration,
    forgotPassword: ForgotPassword,
    resetPassword: ResetPassword,
  };

  //Форма, которая будет отображена для неавторизованых пользователей
  const Form = mainPageForms[mainPageForm] || Auth;

  useEffect(() => {
    //Если в адресной строке есть параметр code, то открываем форму сброса пароля
    if(searchParams.get('code')){
      dispatch(setParam({param: 'mainPageForm', data: 'resetPassword'}))
    }

    if(token){
      setPageLoaded(false);
      setPageData({page: 'main', data: {}});

      getMainPage().then(response => {
        dispatch(setPageData({page: 'main', data: response.data}));
        setPageLoaded(true);
      }).catch(error => {
        if(error.response?.status == 401){
          //Если токен устарел - удаляем его.
          dispatch(logout());
        }
      });

    }else{
      setPageLoaded(true);
    }

  }, [token, language]);

  const icons = {'Mono': monobank, 'PayPal': paypal, 'Bitcoin': bitcoin, 'USDT': usdt}

  const useTelegramAuth = ['radical-plum.com'].includes(window.location.hostname) || true;
  //const useTelegramAuth = false;

  return <>
      <Helmet>
        <title itemProp="name" lang={currentLng}>
          Радикальня | {t("pages.main")}
        </title>
        <meta property="og:description" content={defaultDescription.slice(0, 200)}/>
        <meta property="og:image" content={baseUrl + defaultPic} />
        <meta property="og:image:secure_url" content={baseUrl + defaultPic} />
        <meta property="og:type" content="about" />
        <meta property="og:image:type" content="image/webp" />
        <meta property="og:image:width" content="640" />
        <meta property="og:image:height" content="640" />
        <meta property="og:image:alt" content="Radical logo" />
      </Helmet>

      <Header />
      <MainPageWrapper>
        <Blur />
        <ContentWrapper>
          {token ?
            <div className={styles.main}>
              {pageLoaded ? <>
                <div className={styles.info}>
                  <div>
                    <img src={Logo} alt="" className={styles.logo}/>
                    <Button className={styles.button} link={'https://t.me/+xzup7dizlkQyMjRi'} isExternal>{t('main.telegramChannel')}</Button>
                  </div>
                  <div>
                    {about?.description?.length > 10 && <div className={styles.about}>
                      <div className={styles.title}>{t('main.about')}</div>
                      <div className={styles.text}>
                        {about?.description}
                      </div>
                      <div className={styles.buttonWrapper}>
                        <Button className={styles.button} link={'about'}>{t('main.details')}</Button>
                      </div>
                    </div>}
                    {wallets?.length > 0 && <div className={styles.wallets}>
                      <div className={styles.title}>{t('main.supportTheProject')}</div>
                      <div className={styles.list}>
                        {wallets.map((wallet, index) => <div className={styles.item} key={index}>{icons[wallet.name] && <img src={icons[wallet.name]} className={styles.icon} alt=""/>} {wallet.wallet_id}</div>)}
                      </div>
                    </div>}
                  </div>
                </div>
                <div className={styles.posts}>
                  <div className={styles.title}>{t('main.newLeaks')}</div>
                  {posts.map(post => {
                    return <div key={post.id} className={styles.post} onClick={() => navigate('1/details/'+post.id)}>
                      <LeakCover img={post.cover?.url} title={post.title} className={styles.cover} />
                      <div>
                        <h3 className={styles.title}>{post.title}</h3>
                        <div className={styles.description}>{post.description}</div>
                      </div>
                    </div>
                  })}
                  <div className={styles.toLeaks}>
                    <Button link={'leaks/1'}>{t('main.allLeaks')}</Button>
                  </div>
                </div>
                </> : <Spiner />}
            </div>
           :
              <div className={styles.unAuthSection}>
                <img src={Logo} alt="Radikalnya logo" className={styles.logo} />
                <div className={styles.row}>
                  <div className={styles.formWrapper}>
                    <Form/>
                    {useTelegramAuth && <>
                      {t('main.or')}
                      <TelegramButton botId={botId} dataOnauth={(data) => dispatch(fetchUser(data))}>
                        {t("main.tgButton")}
                      </TelegramButton>
                    </>}
                  </div>
                  <div className={styles.telegramChannelWrapper}>
                    <img src={TgChannelLogo} />
                    <div className={styles.name}>✙ - Радикальня - ✙</div>
                    <Button link={'https://t.me/+xzup7dizlkQyMjRi'} isExternal>{t('main.telegramChannel')}</Button>
                  </div>
                </div>

              </div>
          }
        </ContentWrapper>
      </MainPageWrapper>
    </>;
};

const MainPageWrapper = styled(WrapperWithImage)`
  display: flex;

  nav {
    ul {
      margin: 2rem;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-wrap: wrap;
      gap: 2rem;

      li {
        list-style: none;
      }
    }
  }
`;

const ContentWrapper = styled.div`
  height: 100%;
  width: 100%;
  z-index: 4;
`;
